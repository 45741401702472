<template>
  <ion-menu side="start" menu-id="first" content-id="main">
    <ion-content class="menu-content">
      <div class="menu">
        <MenuButton class="menu__button"/>
        <div class="menu__leagueInfo">
          <div class="menu__leagueInfo__avatar">
            <span class="menu__leagueInfo__avatar__text">{{ team.getPosition }}º</span>
          </div>
          <div class="menu__leagueInfo__data">
            <span class="menu__leagueInfo__name">{{ league.getName }}</span>
            <span class="menu__leagueInfo__date">{{ league.getFormattedStartAt }}</span>
          </div>
        </div>
        <div class="menu__list">
          <div class="menu__listItem" @click="showLeagueSelection()">
            <ion-label>Seleccionar Liga</ion-label>
            <img class="menu__icon" src="/assets/menu_arrow.svg">
            <FullModal
              :openedModal="leagueSelection"
              @onPressBackButton="hideLeagueSelection()"
            >
              <LeagueSelection
                @selected="hideLeagueSelection()"
              />
            </FullModal>
          </div>
          <div class="menu__listItem" @click="showLeagueCreation()">
            <ion-label>Crear Liga</ion-label>
            <img class="menu__icon" src="/assets/menu_arrow.svg">
            <FullModal
              :openedModal="leagueCreation"
              @onPressBackButton="hideLeagueCreation()"
            >
              <LeagueCreation
                @selected="hideLeagueCreation()"
              />
            </FullModal>
            <FullModal
              :openedModal="becomePremium"
              @onPressBackButton="hideBecomePremium()"
            >
              <BecomePremium
                @selected="hideBecomePremium()"
              />
            </FullModal>
          </div>

          <div class="menu__listItem" @click="showLeagueJoin()">
            <ion-label>Unirme a liga</ion-label>
            <img class="menu__icon" src="/assets/menu_arrow.svg">
            <FullModal
              :openedModal="leagueJoin"
              @onPressBackButton="hideLeagueJoin()"
            >
              <LeagueJoin
                joinLeagueTitle="Unirme a una liga"
                @closeModal="hideLeagueJoin()"
              />
            </FullModal>
          </div>
        </div>

        <div class="menu__listItem" @click="showSubscription()">
          <ion-label>Subscripción a premium</ion-label>
          <img class="menu__icon" src="/assets/menu_arrow.svg">
          <FullModal
            :openedModal="subscription"
            @onPressBackButton="hideSubscription()"
          >
            <CurrentSubscription
              @closeModal="hideSubscription()"
              @goToBuy="showBecomePremium"
            />
          </FullModal>
        </div>

        <hr class="divider">

        <div class="menu__userInfo">
          <img class="menu__userInfo__avatar" :src="user.avatar">
          <div class="menu__userInfo__data">
            <span class="menu__userInfo__name">{{ user.getUsername }}</span>
            <span class="menu__userInfo__email">{{ user.getEmail }}</span>
          </div>
          <!--
            <img class="menu__userInfo__edit menu__icon" src="/assets/edit_icon.svg">
          -->
        </div>

        <div class="menu__list">
          <!--
          <div class="menu__listItem" @click="goTo('configuracion')">
            <ion-label>Configuración</ion-label>
            <img class="menu__icon" src="/assets/settings.svg">
          </div>
          -->

          <div class="menu__listItem" @click="goTo('logout')">
            <ion-label>Cerrar sesión</ion-label>
            <img class="menu__icon" src="/assets/logout.svg">
          </div>
        </div>
      </div>
    </ion-content>
  </ion-menu>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { IonMenu, IonLabel, IonContent, menuController } from '@ionic/vue'
import MenuButton from "./MenuButton";
import FullModal from '@/components/FullModal/FullModal'
import LeagueSelection from '@/components/LeagueSelection/LeagueSelection'
import LeagueCreation from '@/components/LeagueCreation/LeagueCreation'
import LeagueJoin from '@/components/LeagueCreation/LeagueJoin.vue'
import BecomePremium from '@/components/Subscription/BecomePremium.vue';
import CurrentSubscription from '../Subscription/CurrentSubscription.vue';

export default {
  name: "Menu",
  components: {
    IonMenu,
    IonLabel,
    IonContent,
    MenuButton,
    FullModal,
    LeagueSelection,
    LeagueCreation,
    LeagueJoin,
    BecomePremium,
    CurrentSubscription,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const leagueSelection = ref(false)
    const leagueCreation = ref(false)
    const becomePremium = ref(false)
    const leagueJoin = ref(false)
    const subscription = ref(false)

    const canCreateLeague = store.getters.getLoggedUser.getCanCreateLeague

    const showLeagueSelection = () => {
      leagueSelection.value = true
      menuController.toggle()
    }
    const hideLeagueSelection = () => {
      leagueSelection.value = false
    }
    const showLeagueCreation = () => {
      if (!canCreateLeague) {
        becomePremium.value = true
        menuController.toggle()
        return
      }

      leagueCreation.value = true
      menuController.toggle()
    }
    const showBecomePremium = () => {
      hideSubscription();
      becomePremium.value = true
      menuController.toggle()
    }
    const hideBecomePremium = () => {
      becomePremium.value = false
    }
    const showSubscription = () => {
      subscription.value = true
      menuController.toggle()
    }
    const hideSubscription = () => {
      subscription.value = false
    }
    const hideLeagueCreation = () => {
      leagueCreation.value = false
    }
    const showLeagueJoin = () => {
      if (!canCreateLeague) {
        becomePremium.value = true
        menuController.toggle()
        return
      }
      leagueJoin.value = true
      menuController.toggle()
    }
    const hideLeagueJoin = () => {
      leagueJoin.value = false
    }

    const user = computed(() => store.getters.getLoggedUser)
    const league = computed(() => store.getters.getCurrentLeague)
    const team = computed(() => store.getters.getCurrentTeam)

    const goTo = (pathName) => {
      menuController.toggle()
      router.push({name: pathName})
    }

    if (route.hash === '#buy') {
      showBecomePremium()
    }

    return {
      user,
      league,
      team,
      leagueSelection,
      leagueCreation,
      becomePremium,
      leagueJoin,
      subscription,

      showLeagueSelection,
      hideLeagueSelection,
      showLeagueCreation,
      hideLeagueCreation,
      showBecomePremium,
      hideBecomePremium,
      showLeagueJoin,
      hideLeagueJoin,
      showSubscription,
      hideSubscription,

      goTo
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './Menu.sass'
</style>
