<template>
  <ion-row v-if="plans.length > 0">
    <ion-col>
      <div class="becomePremium__title">Elije tu plan</div>
      <div class="becomePremium__subtitle">
        Selecciona tu plan perfecto para gestionar tus ligas de fútbol.
      </div>
      <div>
        <PlanCard :plan="freePlan" @selected="$emit('selected')" />
      </div>
      <div v-for="plan in plans" :key="plan">
        <PlanCard :plan="plan" />
      </div>
    </ion-col>
  </ion-row>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useStore } from "vuex";
import { IonRow, IonCol } from "@ionic/vue";
import PlanCard from '../LeagueSelection/PlanCard.vue';

export default {
  name: "BecomePremium",
  components: {
    IonRow,
    IonCol,
    PlanCard,
  },
  emits: ['selected'],
  setup() {
    const store = useStore();
    const plans = ref([]);

    const getPlans = () => {
      store.commit("showLockScreen");
      store.dispatch("getSubscriptionPlans").then((foundPlans) => {
        plans.value = foundPlans;
      }).finally(() => {
        store.commit("hideLockScreen");
      });
    };

    getPlans();

    const freePlan = {
      getId: 'free',
      name: 'Gratis',
      fullPrice: '0 €',
    }

    return {
      plans,
      freePlan,
    };
  },
};
</script>

<style lang="sass" scoped>
  @import 'BecomePremium.sass'
</style>
