import CurrentSubscription from "../models/CurrentSubscription"

class CurrentSubscriptionFactory {
  get (data) {
    const currentSubscription = {
      id: data.id,
      plant: data.plan,
      nextRenewalAt: data.nextRenewalAt,
      isActive: data.isActive
    }

    return new CurrentSubscription(currentSubscription)
  }
}

export default new CurrentSubscriptionFactory()
