export default {
  getToken(state) {
    return state.auth.token
  },
  getRefreshToken(state) {
    return state.auth.refreshToken
  },

  getRef(state) {
    return state.ref
  },

  showLockScreen(state) {
    return state.showLockScreen
  },

  hasUserLoggedIn (state) {
    return state.auth.token !== null
  },
  hasUserData (state) {
    return Object.entries(state.loggedUser).length !== 0
  },

  getLoggedUser (state) {
    return state.loggedUser
  },
  getCurrentLeague (state) {
    return state.currentLeague
  },
  getCurrentTeam (state) {
    return state.currentTeam
  },
  getSeasonStartPopupShown (state) {
    return state.seasonStartPopupShown
  },
  getSeasonEndPopupShown (state) {
    return state.seasonEndPopupShown
  },
  getResultsPopupShown (state) {
    return state.resultsPopupShown
  }
}
