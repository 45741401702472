import { getDefaultState } from '@/store/state'
import { getUserTeamByLeague } from '@/domain/services/TeamService'

export default {
  showLockScreen(state) {
    return state.showLockScreen = true
  },
  hideLockScreen(state) {
    return state.showLockScreen = false
  },

  setRef(state, ref) {
    return state.ref = ref
  },

  setAuth(state, auth) {
    const accessToken = auth.token
    const refreshToken = auth.refreshToken
    state.auth = auth

    localStorage.setItem(process.env.VUE_APP_ACCESS_TOKEN, accessToken)
    localStorage.setItem(process.env.VUE_APP_ACCESS_REFRESH_TOKEN, refreshToken)
  },
  logout(state) {
    localStorage.removeItem(process.env.VUE_APP_ACCESS_TOKEN)
    localStorage.removeItem(process.env.VUE_APP_ACCESS_REFRESH_TOKEN)
    Object.assign(state, getDefaultState())
  },

  setUser(state, user) {
    state.loggedUser = user
  },

  setCurrentLeague(state, league) {
    state.currentLeague = league
  },
  setCurrentTeam(state, data) {
    const team = getUserTeamByLeague(data.league, data.user)

    if (team === null) {
      state.currentTeam = {}
      return
    }

    state.currentTeam = team
  },
  clearCurrentTeam(state) {
    state.currentTeam = {}
  },
  seasonStartPopupShown(state) {
    localStorage.setItem(process.env.VUE_APP_SEASON_START_POPUP_SHOWN, "true")
    state.seasonStartPopupShown = true;
  },
  seasonEndPopupShown(state) {
    localStorage.setItem(process.env.VUE_APP_SEASON_END_POPUP_SHOWN, "true")
    state.seasonEndPopupShown = true;
  },
  resultsPopupShown(state) {
    localStorage.setItem(process.env.VUE_APP_RESULTS_POPUP_SHOWN, "true")
    state.resultsPopupShown = true;
  }
}
