import SubscriptionPlan from "../models/SubscriptionPlan"

const DEFAULT_AMOUNT_DIVISOR = 100

class SubscriptionPlanFactory {
  get (data) {
    const subscriptionPlan = {
      id: data.id,
      name: data.name,
      order: data.order,
      amount: data.amount / DEFAULT_AMOUNT_DIVISOR,
      currency: data.currency,
      url: data.url,
      hasRenewalPeriod: data.hasRenewalPeriod
    }

    return new SubscriptionPlan(subscriptionPlan)
  }
}

export default new SubscriptionPlanFactory()
