<template>
  <a class="planCard" :class="plan.getId" :href="plan.url">
    <div class="planCard__header">
      <div class="planCard__name">
        {{ plan.name }}
        <span class="planCard__name__subtitle">
          {{ subtitle[plan.getId] }}
        </span>
      </div>
      <div class="planCard__price">
        {{ plan.fullPrice }}
        <span class="planCard__price__subtitle">
          {{ priceSubtitle[plan.getId] }}
        </span>
      </div>
    </div>
    <div class="planCard__description">
      <ul>
        <li v-for="(description, index) in descriptions[plan.getId]" :key="index">
          {{ description }}
        </li>
      </ul>
    </div>
    <div>
      <div class="planCard__button">
        <ion-button expand="block" @click="$emit('selected')" v-if="plan.getId == 'free'">Elegir plan gratuito</ion-button>
        <ion-button expand="block" v-else>Ir al Pago</ion-button>
      </div>
    </div>
  </a>
</template>

<script lang="ts">
import { IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: "PlanCard",
  components: {
    IonButton
  },
  emits: ['selected'],
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  setup() {
    const descriptions = {
      free: [
        'Hasta 4 jugadores por liga',
        '1 liga a la vez',
        '1 temporada por liga'
      ],
      monthly: [
        'Hasta 12 por jugadores por liga',
        'Múltiples ligas',
        'Temporadas ilimitadas',
        'Gestión avanzada de fases'
      ],
      yearly: [
        'Hasta 12 por jugadores por liga',
        'Múltiples ligas',
        'Temporadas ilimitadas',
        'Gestión avanzada de fases'
      ],
      lifetime: [
        'Hasta 12 por jugadores por liga',
        'Múltiples ligas',
        'Temporadas ilimitadas',
        'Gestión avanzada de fases',
        'Apoya el desarrollo de la app'
      ]
    };
    const priceSubtitle = {
      free: '',
      monthly: '/mes',
      yearly: '/año',
      lifetime: 'pago único'
    };
    const subtitle = {
      free: '',
      monthly: 'Para empezar a jugar',
      yearly: 'Más popular',
      lifetime: 'Oferta por tiempo limitado'
    };

    return {
      descriptions,
      priceSubtitle,
      subtitle,
    };
  }
});
</script>

<style lang="sass" scoped>
  @import 'PlanCard.sass'
</style>
