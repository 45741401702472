<template>
  <div class="auth__form">
    <ion-row>
      <ion-col>
        <span class="auth__form__title">Bienvenido</span>
        <div class="auth__form__body">
          <Form ref="registerForm" @onSubmit="handleRegister" @keyup.enter="handleRegister">
            <div class="error globalError" v-if="globalError">{{ globalError }}</div>
            <Field name="username" label="nombre" v-slot="{ field, meta }" rules="required|min:3">
              <Input
                  v-bind="field"
                  :valid="!meta.validated || meta.valid"
                  placeholder="Tu nombre"
                  @change="onChangeValue($event, 'username')"
                  backgroundColor="invertedColor"
              />
            </Field>
            <ErrorMessage name="username" v-slot="{ message }">
              <div v-if="message" class="error">{{ message }}</div>
            </ErrorMessage>
            <br/>
            <Field name="fifaId" label="ID de Fifa" v-slot="{ field, meta }" rules="required">
              <Input
                  v-bind="field"
                  :valid="!meta.validated || meta.valid"
                  placeholder="Tu ID de Fifa"
                  @change="onChangeValue($event, 'fifaId')"
                  backgroundColor="invertedColor"
              />
            </Field>
            <ErrorMessage name="fifaId" v-slot="{ message }">
              <div v-if="message" class="error">{{ message }}</div>
            </ErrorMessage>
            <br/>
            <Field name="email" label="correo electrónico" v-slot="{ field, meta }" rules="required|email">
              <Input
                  v-bind="field"
                  :valid="!meta.validated || meta.valid"
                  placeholder="Email"
                  inputType="email"
                  @change="onChangeValue($event, 'email')"
                  backgroundColor="invertedColor"
              />
            </Field>
            <ErrorMessage name="email" v-slot="{ message }">
              <div v-if="message" class="error">{{ message }}</div>
            </ErrorMessage>
            <br/>
            <Field name="password" label="contraseña" v-slot="{ field, meta }" rules="required|password">
              <Input
                  v-bind="field"
                  :valid="!meta.validated || meta.valid"
                  placeholder="Password"
                  inputType="password"
                  @change="onChangeValue($event, 'password')"
                  backgroundColor="invertedColor"
              />
            </Field>
            <ErrorMessage name="password" v-slot="{ message }">
              <div v-if="message" class="error">{{ message }}</div>
            </ErrorMessage>
            <br/>
            <Field name="ref" v-model="form.ref" label="Código promocional" v-slot="{ field, meta }">
              <Input
                  v-bind="field"
                  :valid="!meta.validated || meta.valid"
                  v-model="form.ref"
                  placeholder="Código promocional"
                  @change="onChangeValue($event, 'ref')"
                  backgroundColor="invertedColor"
              />
            </Field>

            <div class="auth__form__body__footer">
              <Field v-model="form.terms" name="terms" label="términos y condiciones" v-slot="{ field, meta }" rules="required">
                <Checkbox
                  v-bind="field"
                  :valid="!meta.validated || meta.valid"
                  :model="form.terms"
                  labelText="Acepto terminos y condiciones"
                  @changeBoolean="onChangeTerms"
                />
              </Field>
              <ErrorMessage name="terms" v-slot="{ message }">
                <div v-if="message" class="error">{{ message }}</div>
              </ErrorMessage>
            </div>

            <PrimaryButton text="Registrarme" @onClick="handleRegister"/>
          </Form>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center auth__help">
        Ya tengo cuenta. <a @click="emit('closeRegisterOpenLogin')">Iniciar sesión</a>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { IonRow, IonCol } from '@ionic/vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Form from "../Input/Form"
import Input from "../Input/Input";
import PrimaryButton from "../Button/PrimaryButton";
import Checkbox from "../Checkbox/Checkbox";
import { Field, ErrorMessage, defineRule } from "vee-validate"
import { email as emailValidator, required, min } from "@vee-validate/rules"
import { passwordValidator } from "@/domain/services/ValidationRules"
import { getMessage } from "@/domain/services/ErrorMessages"

export default {
  name: "RegisterForm",
  components: {
    Checkbox,
    PrimaryButton,
    IonRow,
    IonCol,
    Form,
    Input,
    Field,
    ErrorMessage,
  },
  setup(_, { emit }) {
    const router = useRouter()
    const store = useStore()
    const registerForm = ref()
    const globalError = ref(null)

    const form = reactive({
      username: '',
      email: '',
      fifaId: '',
      password: '',
      ref: store.getters.getRef,
      terms: false,
    })

    const handleRegister = () => {
      registerForm.value.validate()
      .then(({ valid }) => {
        if (!valid) {
          return
        }

        const data = {
          username: form.username,
          fifaId: form.fifaId,
          email: form.email,
          password: form.password,
          ref: form.ref,
          terms: form.terms
        }

        store.dispatch('createUser', data)
        .then(() => {
          const loginData = {
            email: form.email,
            password: form.password
          }
          store.dispatch('login', loginData)
          .then(() => {
            emit('closeModal')
            router.push({name: 'subscriptionAfterRegistration'})
          })
        })
        .catch(error => {
          globalError.value = getMessage(error.errors)
        })
      })
    }

    const onChangeValue = (event, key) => {
      form[key] = event.target.value
    }

    const onChangeTerms = () => {
      form.terms = !form.terms
    }

    defineRule("email", emailValidator)
    defineRule("required", required)
    defineRule("min", min)
    defineRule("password", passwordValidator)

    return {
      emit,

      form,
      registerForm,
      globalError,

      emailValidator,
      required,
      passwordValidator,

      handleRegister,
      onChangeValue,
      onChangeTerms,
    }
  },
};
</script>

<style lang="sass" scoped>
  @import './Auth.sass'
</style>
