export default class CurrentSubscription {
  constructor (data) {
    this.id = data.id,
    this.plan = data.plan
    this.nextRenewalAt = data.nextRenewalAt
    this.isActive = data.isActive
  }

  get getId () {
    return this.id
  }

  get getPlay () {
    return this.plan
  }

  get getNextRenewalAt () {
    return this.nextRenewalAt
  }

  get getIsActive () {
    return this.isActive
  }
}
