<template>
  <section class="currentSubscription">
    <ion-row v-if="currentSubscription">
      <ion-col>
        <ion-card class="currentSubscription__card">
          <ion-card-header>
            <ion-card-title class="currentSubscription__card__title">Esta es tu suscripción actual</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list>
              <ion-item>
                <ion-label>Plan</ion-label>
                <ion-note slot="end" v-if="currentSubscription.getPlan === 'monthly'">
                  Mensual
                </ion-note>
                <ion-note slot="end" v-if="currentSubscription.getPlan === 'yearly'">
                  Anual
                </ion-note>
                <ion-note class="currentSubscription__card__note" slot="end" v-else>
                  ¡Para siempre!
                </ion-note>
              </ion-item>
              <ion-item>
                <ion-label>Fecha de fin</ion-label>
                <ion-note slot="end" v-if="currentSubscription.nextRenewalAt">
                  {{ currentSubscription.nextRenewalAt }}
                </ion-note>
                <ion-note class="currentSubscription__card__note" slot="end" v-else>
                  Sin fecha de expiración
                </ion-note>
              </ion-item>
              <ion-item>
                <ion-label>Estado</ion-label>
                <ion-note slot="end" class="currentSubscription__card__note" v-if="currentSubscription.isActive">Activo</ion-note>
                <ion-note slot="end" class="currentSubscription__card__note" v-else>Inactivo</ion-note>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
        <div v-if="!currentSubscription.isActive">
          Conviértete en usuario <a href="#" @click.prevent="$emit('goToBuy')">
            premium para disfrutar de todas las ventajas.
          </a>
        </div>
      </ion-col>
    </ion-row>
    <ion-row v-else>
      <ion-col>
        <div>No tienes ninguna suscripción activa.</div>
        <div>Pero puedes <a href="#" @click.prevent="$emit('goToBuy')">hacerte premium fácilmente</a>.</div>
      </ion-col>
    </ion-row>
  </section>
</template>

<script>
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonItem, IonLabel, IonList, IonNote, IonRow } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "CurrentSubscription",
  emits: ["goToBuy"],
  components: {
    IonRow,
    IonCol,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonList,
    IonItem,
    IonLabel,
    IonNote,
  },
  setup() {
    const store = useStore();
    const currentSubscription = ref(null);

    const getCurrentSubscription = () => {
      store.commit("showLockScreen");
      store.dispatch("getCurrentSubscription").then((res) => {
        currentSubscription.value = res;
      }).finally(() => {
        store.commit("hideLockScreen");
      });
    };

    getCurrentSubscription();

    return {
      currentSubscription,
    };
  },
});
</script>

<style lang="sass" scoped>
 @import "CurrentSubscription"
</style>
