<template>
  <div class="playerProfile">
    <ion-row>
      <ion-col>
        <div class="playerProfile__avatar">
          <PlayerPhoto :player="player" />
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="playerProfile__name">
        {{ player.getName }}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="playerProfile__features">
        <PlayerFlag :player="player" />
        <PlayerClub :player="player" />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="playerProfile__financials">
        {{ player.getFormattedTerminationClause }} · {{ player.getFormattedSalary }}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="playerProfile__main-stats">
        <PlayerStat
          title="Rtg"
          :value="player.getRTG"
        />
        <PlayerStat
          title="Cat"
          :value="player.getCategory"
        />
        <PlayerStat
          title="Obj"
          :value="player.getObjective"
        />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="playerProfile__stats">
        <div>
          <div class="title">Posición</div>
          <div class="value">{{ player.getPosition }}</div>
        </div>
        <div>
          <div class="title">Club</div>
          <div class="value">{{ player.getClub }}</div>
        </div>
        <div>
          <div class="title">Liga</div>
          <div class="value">{{ player.getDivision }}</div>
        </div>
        <div>
          <div class="title">Nacionalidad</div>
          <div class="value">{{ player.getNationality }}</div>
        </div>
        <div>
          <div class="title">PAC</div>
          <div class="value">{{ player.getPAC }}</div>
        </div>
        <div>
          <div class="title">SHO</div>
          <div class="value">{{ player.getSHO }}</div>
        </div>
        <div>
          <div class="title">PAS</div>
          <div class="value">{{ player.getPAS }}</div>
        </div>
        <div>
          <div class="title">DRI</div>
          <div class="value">{{ player.getDRI }}</div>
        </div>
        <div>
          <div class="title">DEF</div>
          <div class="value">{{ player.getDEF }}</div>
        </div>
        <div>
          <div class="title">PHY</div>
          <div class="value">{{ player.getPHY }}</div>
        </div>
        <div>
          <div class="title">WF</div>
          <div class="value">{{ player.getWF }}★</div>
        </div>
        <div>
          <div class="title">Habilidad</div>
          <div class="value">{{ player.getSkill }}★</div>
        </div>
        <!--
        <div>
          <div class="title">AW</div>
          <div class="value">{{ player.getAW }}</div>
        </div>
        <div>
          <div class="title">DW</div>
          <div class="value">{{ player.getDW }}</div>
        </div>
        -->
      </ion-col>
    </ion-row>
    <div class="playerProfile__button">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import {
  IonRow,
  IonCol,
} from '@ionic/vue'
import Player from '@/domain/models/Player'
import PlayerStat from '@/components/PlayerInfoCard/PlayerStat.vue'
import PlayerPhoto from '@/components/PlayerInfoCard/PlayerPhoto.vue';
import PlayerFlag from '@/components/PlayerInfoCard/PlayerFlag.vue';
import PlayerClub from '@/components/PlayerInfoCard/PlayerClub.vue';

export default {
  components: {
    IonRow,
    IonCol,
    PlayerStat,
    PlayerPhoto,
    PlayerFlag,
    PlayerClub,
  },
  emits: ['bought'],
  props: {
    player: Player
  },
  setup(_, { emit }) {
    const bought = (player) => {
      emit('bought', player)
    }

    return {
      bought
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'PlayerProfile.sass'
</style>
