import { createRouter, createWebHistory } from '@ionic/vue-router';
import SelectAuth from "@/views/Auth/SelectAuth.vue";
import AcceptLegalTerms from "../views/Legal/AcceptLegalTerms";
import LeagueSelector from "../views/LeagueSelector";
import SplashScreen from "../views/SplashScreen/SplashScreen";
import LeagueWelcome from "../views/League/LeagueWelcome";
import Welcome from "../views/Welcome/Welcome";
import Tabs from "../views/Tabs/Tabs";
import TabLeague from "../views/Tabs/TabLeague";
import TabTeam from "../views/Tabs/TabTeam";
import TabMarket from "../views/Tabs/TabMarket";
import Draft from "../views/Draft/Draft";
import { store } from '@/store'
import StripePayment from '../components/Payments/StripePayment';
import PaymentAfterPayment from '../views/PaymentAfterPayment/PaymentAfterPayment.vue';

const routes = [
  {
    path: '/',
    redirect: '/splash-screen',
    meta: {
      public: true
    }
  },
  {
    path: '/splash-screen',
    component: SplashScreen,
    meta: {
      public: true
    }
  },
  {
    path: '/select-auth',
    name: 'selectAuth',
    component: SelectAuth,
    meta: {
      public: true
    }
  },
  {
    path: '/reset-password/:token',
    name: 'resetPassword',
    component: () => import("@/views/Auth/ResetPassword"),
    meta: {
      public: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: async (_, __, next) => {
      store.commit('logout')

      next({ name: 'selectAuth' })
    },
    meta: {
      public: false
    }
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: Welcome,
    meta: {
      public: false,
      requiresAuth: true
    }
  },
  {
    path: '/subscribe',
    name: 'subscriptionAfterRegistration',
    component: PaymentAfterPayment,
    meta: {
      public: false,
      requiresAuth: true
    }
  },
  {
    path: '/legal-terms',
    name: 'legal_terms',
    component: AcceptLegalTerms,
    meta: {
      public: false
    }
  },
  {
    path: '/select-league',
    name: 'select_league',
    component: LeagueSelector,
    meta: {
      public: false
    }
  },
  {
    path: '/league-welcome',
    name: 'league_welcome',
    component: LeagueWelcome,
    meta: {
      public: false,
      requiresAuth: true
    }
  },
  {
    path: '/entryPoint',
    name: 'entryPoint',
    beforeEnter: async (_, __, next) => {
      const leagues = await store.dispatch('getUserLeagues')

      if (leagues.length == 0) {
        next({ name: 'welcome' })
        return
      }

      next({ name: 'news', params: { id: leagues[0].getId } })
    },
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/payments/s/:checkout_session_id',
    name: 'stripe_payment',
    component: StripePayment,
    meta: {
      public: false,
      requiresAuth: true
    }
  },
  {
    path: '/liga/:id/draft',
    name: 'draft',
    component: Draft,
    meta: {
      public: false,
      requireLeague: true,
      requiresAuth: true
    }
  },
  {
    path: '/tabs/liga/:id/',
    component: Tabs,
    meta: {
      public: false,
      requireLeague: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'noticias',
        name: 'news',
        component: () => import("@/views/Tabs/TabNews")
      },
      {
        path: 'liga',
        component: TabLeague
      },
      {
        path: 'equipo',
        name: 'team',
        component: TabTeam
      },
      {
        path: 'mercado',
        name: 'market',
        component: TabMarket
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve(() => {
  location.search.replace(/^\?*/, '').split('&').forEach((param) => {
    const [key, value] = param.split('=')
    if (key === 'ref') {
      store.commit('setRef', value)
    }
  })
})

router.beforeEach(async (to, _, next) => {
  const hasUserLoggedIn = store.getters.hasUserLoggedIn
  let hasUserData = store.getters.hasUserData

  if (hasUserLoggedIn && to.meta.public) {
    next({ name: 'entryPoint' })
    return
  }

  if (to.meta.public && !to.meta.loadData) {
    next()
    return
  }

  if (to.meta.requiresAuth && !hasUserLoggedIn) {
    next({ name: 'selectAuth' })
    return
  }

  if (to.meta.requiresAuth && hasUserLoggedIn) {
    if (!hasUserData) {
      await store.dispatch('getUser')
        .then(() => {
          hasUserData = true
        })
        .catch((res) => {
          if (res.code === 401) {
            next({ name: 'logout' })
            return
          }
      })
    }
  }

  if (to.meta.requiresAuth && hasUserLoggedIn && hasUserData && typeof(to.params.id) !== 'undefined') {
    const currentLeague = store.getters.getCurrentLeague
    const currentTeam = store.getters.getCurrentTeam
    if (to.params.id !== currentLeague.id || Object.keys(currentTeam).length == 0) {
      const league = await store.dispatch('getLeague', to.params.id)
        .catch(error => {
          console.log(error)
          next({ name: 'logout' })
        })
      const user = store.getters.getLoggedUser

      store.commit('setCurrentTeam', { user: user, league: league })
      const team = store.getters.getCurrentTeam

      if (team.getTeamPlayers.length == 0) {
        next({ name: 'draft', params: { id: to.params.id } })
        return
      }
    }
  }

  if (!to.meta.requiresAuth || hasUserLoggedIn) {
    next()
    return
  }

  next({ name: 'logout' })
})

export default router
