import axios from '@/plugins/axios'
import SubscriptionPlanFactory from '../domain/services/SubscriptionPlanFactory'
import { uuid } from 'vue-uuid'
import CurrentSubscriptionFactory from '../domain/services/CurrentSubscriptionFactory'

const URL_GET_SUBSCRIPTION_PLANS = '/api/products'

const URL_VALIDATE_SUBSCRIPTION = '/api/payment/subscription/{id}'
const URL_GET_CURRENT_SUBSCRIPTION = '/api/payment/subscription'

export function getSubscriptionPlans() {
  return axios
    .get(URL_GET_SUBSCRIPTION_PLANS)
    .then(res => {
      return res.data.collection.map(SubscriptionPlanFactory.get)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function validateSubscription(checkout_session_id) {
  const id = uuid.v4();
  const url = URL_VALIDATE_SUBSCRIPTION.replace('{id}', id)
  return axios
    .put(url, {
      provider_subscription_id: checkout_session_id
    })
    .then(res => {
      return res.data
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getCurrentSubscription() {
  return axios
    .get(URL_GET_CURRENT_SUBSCRIPTION)
    .then(res => {
      return CurrentSubscriptionFactory.get(res.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
