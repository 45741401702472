export default class SubscriptionPlan {
  constructor (data) {
    this.id = data.id,
    this.name = data.name,
    this.order = data.order,
    this.amount = data.amount,
    this.currency = data.currency,
    this.url = data.url
    this.hasRenewalPeriod = data.hasRenewalPeriod
  }

  get getId () {
    return this.id
  }

  get getName () {
    return this.name
  }

  get getOrder () {
    return this.order
  }

  get getAmount () {
    return this.amount
  }

  get fullPrice () {
    return Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: this.currency
    }).format(this.amount)
  }

  get getCurrency () {
    return this.currency
  }

  get getUrl () {
    return this.url
  }

  get getHasRenewalPeriod () {
    return this.hasRenewalPeriod
  }
}
