<template>
  <ion-page>
    <ion-content>
      <section class="payment-after-payment">
        <BecomePremium @selected="continueToWelcomePage()" />
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BecomePremium from "../../components/Subscription/BecomePremium.vue";
import { useRouter } from "vue-router";
import { IonPage, IonContent } from "@ionic/vue";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    BecomePremium,
  },
  name: "PaymentAfterPayment",
  setup() {
    const router = useRouter();

    const continueToWelcomePage = () => {
      router.push({ name: "welcome", replace: true });
    };
    return {
      continueToWelcomePage,
    };
  },
});
</script>

<style lang="scss" scoped>
.payment-after-payment {
  padding: 3rem 20px;
}
</style>
