export const getDefaultState = () => ({
  auth: {
    token: localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN) || null,
    refreshToken: localStorage.getItem(process.env.VUE_APP_ACCESS_REFRESH_TOKEN) || null
  },
  loggedUser: {},
  currentLeague: {},
  currentTeam: {},

  showLockScreen: false,

  ref: '',

  seasonStartPopupShown: localStorage.getItem(process.env.VUE_APP_SEASON_START_POPUP_SHOWN) == "true" || false,
  seasonEndPopupShown: localStorage.getItem(process.env.VUE_APP_SEASON_END_POPUP_SHOWN) == "true" || false,
  resultsPopupShown: localStorage.getItem(process.env.VUE_APP_RESULTS_POPUP_SHOWN) == "true" || false,
})

export default getDefaultState()
