<template>
  <ion-page>
    <ion-content>
      <section class="stripePayment">
        <ion-row v-if="!hasError">
          <ion-col>
            <div class="stripePayment__title">¡Pago recibido!</div>
            <div class="stripePayment__description">
              Ya eres usuario premium en Fifantasy. ¡Gracias por tu confianza!
            </div>
            <ion-button class="error" mode="ios" @click="goBack">
              Volver a la página inicial
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row v-else>
          <ion-col>
            <div class="stripePayment__title">Error</div>
            <div class="stripePayment__description">
              Hubo un problema al procesar el pago. Por favor, ponte en contacto con nosotros
            </div>
            <ion-button class="error" mode="ios" @click="goBack">
              Volver a la página inicial
            </ion-button>
          </ion-col>
        </ion-row>
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonButton, IonCol, IonContent, IonPage, IonRow } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "StripePayment",
  components: {
    IonPage,
    IonContent,
    IonRow,
    IonCol,
    IonButton,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const hasError = ref(false);

    const validatePayment = (checkout_session_id) => {
      store.commit("showLockScreen");
      store
        .dispatch("validateSubscription", checkout_session_id)
        .then((res) => {
          console.log(res);
        })
        .catch(() => {
          hasError.value = true;
        })
        .finally(() => {
          store.commit("hideLockScreen");
        });
    };

    const goBack = () => {
      router.push({ name: "entryPoint", replace: true });
    };

    validatePayment(route.params.checkout_session_id);

    return {
      hasError,

      goBack,
    };
  },
});
</script>

<style lang="sass" scoped>
  @import './StripePayment.sass'
</style>
